import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createCategory,
  getAProductCategory,
  resetState,
  updateAProductCategory,
} from "../features/pcategory/pcategorySlice";
import Dropzone from "react-dropzone";
import { base_imageurl, base_url } from "../utils/baseUrl";
import axios from "axios";


let schema = yup.object().shape({
  name: yup.string().required("Category Name is Required"),
  image: yup.string().required("Image is Required"),
});

const Addcat = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const getPCatId = location.pathname.split("/")[3];
  const navigate = useNavigate();
  const newCategory = useSelector((state) => state.pCategory);
  const {
    isSuccess,
    isError,
    isLoading,
    createdCategory,
    categoryName,
    categoryimage,
    updatedCategory,
  } = newCategory;
  // console.log(newCategory);

  useEffect(() => {
    //  console.log( getPCatId);
    //   return
    if (getPCatId !== undefined) {
      dispatch(getAProductCategory(getPCatId))
        .then((data) => {
          // console.log(data.payload,'====');
          formik.setFieldValue('name', data.payload.name); // Set the selected image to the form field
          formik.setFieldValue('image', base_imageurl + data.payload.image); // Set the selected image to the form field

        })
    } else {
      dispatch(resetState());
    }
  }, [getPCatId]);
  
  useEffect(() => {
    if (isSuccess && createdCategory) {
      toast.success("Category Added Successfullly!");
    }
    if (isSuccess && updatedCategory) {
      toast.success("Category Updated Successfullly!");
      navigate("/admin/list-category");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);


  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      name: "",
      image: "",
    },
    validationSchema: schema,

    onSubmit: async (values) => {
      console.log(values);
      if (getPCatId !== undefined) {
        const data = { id: getPCatId, pCatData: values };

        let CheckImageStatus = /^http:\/\//.test(values?.image) && typeof values?.image === 'string' ? true : false;
        // console.log(data, CheckImageStatus);
        // return
        if (!CheckImageStatus) {
          let formimage = new FormData()
          formimage.append('files', values?.image,);
          axios.post(`${base_url}image`, formimage)
            .then(async (response) => {
              // console.log(response.status);
              if (response.status === 200) {
                let updateValue = {
                  ...data,
                  pCatData: {
                    ...data.pCatData,
                    image: `images/${response.data.data.filename}`
                  }
                };
                // console.log(updateValue, 'success');
                // return
                dispatch(updateAProductCategory(updateValue));
                dispatch(resetState());
              }
            })
            .catch((err) => {
              alert("Server Error:", err)
            })
          return
        }
        else {
          const imageName = values.image.match(/\/images\/(.*)$/)[1];
          let updateValue = {
            ...data,
            pCatData: {
              ...data.pCatData,
              image: `images/${imageName}`
            }
          };
          dispatch(updateAProductCategory(updateValue));
          dispatch(resetState());
        }
      } else {
        let formimage = new FormData()
        formimage.append('files', values.image,);

        axios.post(`${base_url}image`, formimage)
          .then(async (data) => {
            if (data.status == 200) {
              let updateValue = {
                ...values,
                image: `images/${data.data.data.filename}`
              }
              dispatch(createCategory(updateValue))
                .then((response) => {
                  if (response?.payload?.response?.status == 400) {
                    alert(response.payload.response.data.message);
                    setLoading(false)
                    return
                  }
                  alert(response?.payload?.msg)
                  formik.resetForm();
                  setTimeout(() => {
                    dispatch(resetState());
                  }, 300);
                })
                .catch((error) => {
                  alert("Error creating category:", error)
                  console.error("Error creating category:", error);
                });
            }
            // if(data?.status == true)
          })
          .catch((err) => {
            alert("Server Error:", err)
          })
        return
      }
    },
  });

  const uploadImg = (props) => {
    const image = props[0];
    // console.log(url);
    // return
    setSelectedImage(image);

    // console.log(props);
    formik.setFieldValue('image', image); // Set the selected image to the form field

  }


  return (
    <div>
      <h3 className="mb-4  title">
        {getPCatId !== undefined ? "Edit" : "Add"} Category
      </h3>
      <div>
        <form action="" onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            label="Enter Product Category Name"
            onChng={formik.handleChange("name")}
            onBlr={formik.handleBlur("name")}
            val={formik.values.name}
            id="brand"
          />
          <div className="error">
            {formik.touched.name && formik.errors.name}
          </div>
          <div className="bg-white border-1 p-5 text-center" style={{ marginTop: 20 }}>
            <Dropzone onDrop={uploadImg} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <section style={{ marginTop: 0 }}>
                  {formik.values.image ? (
                    <div onClick={uploadImg}>
                      {
                        formik?.values.image ? (
                          typeof formik.values.image === 'string' && /^http:\/\//.test(formik.values.image) ? (
                            // If image URL is from the server
                            <>
                              <h3 style={{ fontSize: 18 }}>Selected Image: {formik.values.image.split('/').pop()}</h3>
                              <img
                                src={formik.values.image}
                                alt="Selected"
                                style={{ maxWidth: '100%', maxHeight: '200px' }}
                              />
                            </>
                          ) : (
                            // If image is from local storage
                            <>
                              <h3 style={{ fontSize: 18 }}>Selected Image: {formik.values.image.name}</h3>
                              <img
                                src={URL.createObjectURL(formik.values.image)}
                                alt="Selected"
                                style={{ maxWidth: '100%', maxHeight: '200px' }}
                              />
                            </>
                          )) : null
                      }
                    </div>
                  ) : (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  )}
                </section>
              )}
            </Dropzone>
          </div>
          <div className="error">
            {formik.touched.image && formik.errors.image}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            {getPCatId !== undefined ? "Edit" : "Add"} Category
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addcat;
