import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BiEdit } from "react-icons/bi";
import { MdCheckCircle, MdCancel } from "react-icons/md"; // Import confirm and reject icons

import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { deleteOrderById, getOrders, updateOrderById } from "../features/auth/authSlice";
import CustomModal from "../components/CustomModal";
import axios from "axios";
import { base_url } from "../utils/baseUrl";
import { toast } from "react-toastify";
import { WEB_Color } from "../consts/Colors";
import { updateProductQty } from "../features/product/productSlice";
const { Option } = Select;


const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Number",
    dataIndex: "number",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Product",
    dataIndex: "product",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const Orders = () => {
  const [openModal, setOpenModal] = useState({
    open: false,
    type: null,
    data: null,
  });
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({ bidtype: null, status: null });
  const orderState = useSelector((state) => state.auth.orders);
  const {
    isSuccess,
    isError,
    isLoading,
    orderUpdate
  } = orderState;

  // console.log(isSuccess,
  //   isError,
  //   isLoading,
  //   orderUpdate,'hel');

  useEffect(() => {
    if (isSuccess && orderUpdate) {
      toast.success("Order Updated Successfullly!");
      // navigate("/admin/list-category");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  useEffect(() => {
    dispatch(getOrders());
  }, []);

  // const data1 = [];
  // for (let i = 0; i < orderState.length; i++) {
  //   data1.push({
  //     key: i + 1,
  //     email: orderState[i].Email,
  //     number: orderState[i].PhoneNumber,
  //     amount: orderState[i].Price,
  //     product: (
  //       <Link to={`/admin/order/${orderState[i]._id}`}>
  //         View Orders
  //       </Link>
  //     ),
  //     status: orderState[i].status,
  //     date: new Date(orderState[i].createdAt).toLocaleString(),
  //     action: (
  //       <>
  //         <Link to="/" className=" fs-3 text-danger">
  //           <BiEdit />
  //         </Link>
  //         <Link className="ms-3 fs-3 text-danger" to="/">
  //           <AiFillDelete />
  //         </Link>
  //       </>
  //     ),
  //   });
  // }

  const handleFilterChange = (key, value) => {
    // console.log(key, value, '=====>');
    setFilters({ ...filters, [key]: value });
  };

  const filteredData = orderState.filter((order) => {
    if (filters.bidtype && order.bidtype !== filters.bidtype) return false;
    if (filters.status && filters.status !== 'All' && order.status !== filters.status) return false;
    // if (filters.status && filters.status !== 'All') return false;
    return true;
  }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));;

  const data = filteredData.map((order, index) => ({
    key: index + 1,
    email: order.Email,
    number: order.PhoneNumber,
    amount: order.Price,
    product: (
      <Link to={`/admin/order/${order._id}`}>
        View Orders
      </Link>
    ),
    status: order.status,
    date: new Date(order.createdAt).toLocaleString(),
    action: (
      <>
        {order.status === 'Completed' ? (
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <button
              onClick={() => setOpenModal({ ...openModal, open: true, type: 'Reject', data: order })}
              style={{
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: WEB_Color.main,
                fontSize: '15px',
                borderRadius: 5,
                marginRight: 5,
                width: "100%", // Full width
                height: 30,
                color: WEB_Color.white
              }}
            >
              Delete
            </button>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={() => setOpenModal({ ...openModal, open: true, type: 'Reject', data: order })}
              style={{
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: WEB_Color.main,
                fontSize: '15px',
                borderRadius: 5,
                marginRight: 5,
                fontSize: 12,
                // width: 60,
                width: '50%',
                height: 30,
                color: WEB_Color.white
              }}
            >
              Reject
            </button>
            {order.status === 'Inprocess' ? (
              <button
                onClick={() => setOpenModal({ ...openModal, open: true, type: 'Completed', data: order })}
                style={{
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: WEB_Color.blue,
                  borderColor: "#52c41a",
                  fontSize: "15px",
                  marginRight: "8px",
                  borderRadius: 5,
                  fontSize: 12,
                  width: '50%',
                  height: 30,
                  color: 'white'
                }}
              >
                Confirm
              </button>
            ) : (
              <button
                onClick={() => setOpenModal({ ...openModal, open: true, type: 'Confirm', data: order })}
                style={{
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: "#52c41a",
                  fontSize: "15px",
                  marginRight: "8px",
                  borderRadius: 5,
                  fontSize: 12,
                  width: '50%',
                  height: 30,
                }}
              >
                Process
              </button>
            )}
          </div>
        )}
      </>

    ),
  }));

  const hideModal = () => {
    setOpenModal({ ...openModal, open: false });
  };

  const handleOrder = async (props) => {
    let send = {
      id: props?._id,
      status: 'Pending'
    }

    if (openModal?.type == 'Confirm') {
      // console.log(send, 'Confirm');
      // return
      dispatch(updateOrderById({ ...send, status: 'Inprocess' }))
        .then(async (res) => {
          if (res.payload.status) {
            // console.log(res.payload.data);
            // return
            await sendEmail(res.payload.data);
            setOpenModal({ ...openModal, open: false })
            // console.log(res, 'responce');
            dispatch(getOrders())
            toast.success("Order Updated Successfullly!");
          }
        })
        .catch((err) => {
          console.log(err, 'Error');
        })
      return
    }
    if (openModal?.type == 'Completed') {
      // console.log(id, 'Confirm');
      dispatch(updateOrderById({ ...send, status: 'Completed' }))
        .then((res) => {
          if (res.payload.status) {
            sendEmail(res?.payload?.data);
            let productsarray = res.payload.data.Products
            productsarray.map((item) => {
              let update = {
                ...item,
                quantity: -Math.abs(item.quantity)
              }
              // console.log(update);
              // return
              dispatch(updateProductQty(update))
            })
            setOpenModal({ ...openModal, open: false })
            // console.log(res, 'responce');
            dispatch(getOrders())
            // toast.success("Order Updated Successfullly!");
          }
        })
        .catch((err) => {
          console.log(err, 'Error');
        })
      return
    }
    else {
      // console.log(props, 'Delete');
      dispatch(deleteOrderById({ ...send, status: 'Completed' }))
        .then((res) => {
          if (res.payload.status) {
            setOpenModal({ ...openModal, open: false })
            // console.log(res, 'responce');
            dispatch(getOrders())
            toast.success("Order Deleted Successfullly!");
          }
        })
        .catch((err) => {
          console.log(err, 'Error');
        })

      return
    }
  }


  const sendEmail = (body) => {
    // console.log(body);
    // console.log(`${base_url}auth/sendEmail`);
    // return
    try {
      axios.post(`${base_url}auth/sendEmail`, body)
        .then(async (item) => {
          console.log(item?.data);
        })
        .catch((err) => {
          console.log('Front-End Error', err);
        })
    } catch (e) {
      console.log(e);
    }
  };



  return (
    <div>
      <h3 className="mb-4 title">Orders</h3>
      <Select
        style={{ width: 200, marginBottom: 10 }}
        placeholder="Select Bid Type"
        onChange={(value) => handleFilterChange("bidtype", value)}
      >
        <Option value="Auction">Auction</Option>
        <Option value="Offer">Offer</Option>
      </Select>
      <Select
        style={{ width: 200, marginBottom: 10 }}
        placeholder="Select Order Status"
        onChange={(value) => handleFilterChange("status", value)}
      >
        <Option value="All">All</Option>
        <Option value="Pending">Pending</Option>
        <Option value="Inprocess">Inprocess</Option>
        <Option value="Completed">Completed</Option>
      </Select>
      <div>{<Table columns={columns} dataSource={data} />}</div>



      <CustomModal
        hideModal={hideModal}
        open={openModal.open}
        performAction={() => {
          handleOrder(openModal.data);
        }}
        title={openModal.type == "Confirm" ? "Are you sure you want to confirm this Order?" : openModal.type == "Completed" ? "Are you sure you want to compelete this Order?" : "Are you sure you want to delete this Order?"}
      />
    </div>
  );
};

export default Orders;
