import React, { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/auth/authSlice";
import image from '../assets/bid101logo.svg'
import axios from "axios";
import { useAuth } from "../constans/store/auth";
import { WEB_Color } from "../consts/Colors";
import { base_url } from "../utils/baseUrl";

let schema = yup.object().shape({
  email: yup
    .string()
    .email("Email should be valid")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});
const Login = () => {
  const [isadmin, setIsAdmin] = useState("")
  const dispatch = useDispatch();
  const { storeTokenInLS } = useAuth();

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,

    onSubmit: (values) => {
      // console.log(values.email);
      authLoginAdmin(values)
      // dispatch(login(values));
    },
  });

  const authLoginAdmin = (props) => {
    // console.log(props, '===');
    try {
      axios.post(`${base_url}auth/login`, props)
        .then(async (item) => {
          console.log(item.data);
          if (item.data.isAdmin) { // Check if user isAdmin
            // alert('Admin login successfull.');
            storeTokenInLS(item?.data)
            // navigate('admin')
            alert(item.data?.msg)
            setIsAdmin('Accepted')
            window.location.reload();

            return;
          }
          else {
            setIsAdmin('Rejected')
          }
        })
        .catch((e) => {
          if (e.request && e.request.response) {
            let err = JSON.parse(e.request.response);
            alert(err.message);
          } else {
            console.error("Error occurred:", e.message);
            alert(`Error occurred: ${e.message}`);
          }
          // setIsAdmin('Rejected')

          // let err = JSON.parse(e.request.response)
          // alert(err.message)
          // setLoading(false)
          // console.log(JSON.parse(e.request.response));
        })
    } catch (e) {
      console.log(e);
    }
  }
  // const authState = useSelector((state) => state);

  // const { user, isError, isSuccess, isLoading, message } = authState.auth;

  // useEffect(() => {
  //   if (isSuccess) {
  //     navigate("admin");
  //   } else {
  //     navigate("");
  //   }
  // }, [user, isError, isSuccess, isLoading]);
  return (
    <div className="py-5" style={{ background: WEB_Color.main, minHeight: "100vh" }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="my-5 w-25 bg-white rounded-3 mx-auto p-4">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom:20,
        }}>
          <img src={image} alt="Description of the image" style={{
            width: '50%', // Ensures the image takes up the full width of its container
            height: '50%'
          }} />
        </div>
        <h3 className="text-center title">Login</h3>
        <p className="text-center">Login to your account to continue.</p>
        <div className="error text-center">
          {isadmin == "Rejected" ? "You are not an Admin" : ""}
        </div>
        <form action="" onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            label="Email Address"
            id="email"
            name="email"
            onChng={formik.handleChange("email")}
            onBlr={formik.handleBlur("email")}
            val={formik.values.email}
          />
          <div className="error mt-2">
            {formik.touched.email && formik.errors.email}
          </div>
          <CustomInput
            type="password"
            label="Password"
            id="pass"
            name="password"
            onChng={formik.handleChange("password")}
            onBlr={formik.handleBlur("password")}
            val={formik.values.password}
          />
          <div className="error mt-2">
            {formik.touched.password && formik.errors.password}
          </div>
          <div className="mb-3 text-end">
            <Link to="forgot-password" className="">
              Forgot Password?
            </Link>
          </div>
          <button
            className="border-0 px-3 py-2 text-white fw-bold w-100 text-center text-decoration-none fs-5"
            style={{ background: WEB_Color.main }}
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
