import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { getOrderByUser, getOrders } from "../features/auth/authSlice";
const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Product Name",
    dataIndex: "name",
  },
  {
    title: "Bid-Type",
    dataIndex: "bildtype",
  },
  {
    title: "Category",
    dataIndex: "category",
  },
  {
    title: "Condition",
    dataIndex: "condition",
  },
  {
    title: "Count",
    dataIndex: "count",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  // {
  //   title: "Action",
  //   dataIndex: "action",
  // },
];

const ViewOrder = () => {
  const location = useLocation();
  const userId = location.pathname.split("/")[3];
  // console.log(userId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderByUser(userId));
  }, []);


  const orderState = useSelector((state) => state.auth.orderbyuser);
  // console.log(orderState);
  const data1 = [];
  for (let i = 0; i < orderState?.Products?.length; i++) {
    data1.push({
      key: i + 1,
      name: orderState.Products[i].title,
      bildtype: orderState.Products[i].bidtype,
      category: orderState.Products[i].category,
      condition: orderState.Products[i].condition,
      count: orderState.Products[i].quantity,
      price: orderState.Products[i].price,
      amount: orderState.Products[i].totalPrice,
      // action: (
      //   <>
      //     <Link to="/" className=" fs-3 text-danger">
      //       <BiEdit />
      //     </Link>
      //     <Link className="ms-3 fs-3 text-danger" to="/">
      //       <AiFillDelete />
      //     </Link>
      //   </>
      // ),
    });
  }
  return (
    <div>
      <h3 className="mb-4 title">View Order</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <div style={{ marginTop: '20px', padding: '20px', border: '1px solid #e8e8e8', borderRadius: '5px', backgroundColor: 'white' }}>
        <h4 style={{ marginBottom: '10px', color: '#333' }}>Order Details</h4>
        <p style={{ marginBottom: '5px', fontSize: '16px', color: '#666' }}>Order-Type: {orderState?.bidtype}</p>
        <p style={{ marginBottom: '5px', fontSize: '16px', color: '#666' }}>{orderState?.bidtype} Price: {orderState?.Price}</p>
        <p style={{ marginBottom: '5px', fontSize: '16px', color: '#666' }}>Email: {orderState?.Email}</p>
        <p style={{ marginBottom: '5px', fontSize: '16px', color: '#666' }}>Phone Number: {orderState?.PhoneNumber}</p>
        <p style={{ marginBottom: '5px', fontSize: '16px', color: '#666' }}>Address: {orderState?.Address}</p>
      </div>
    </div>
  );
};

export default ViewOrder;
